import CheckMark from "./CheckMark";
import Checkbox from "./Checkbox";
import UnChecked from "./UnChecked";

export default function ListItem ({ text, available, level }) {
    if (level && level === "Custom") {
        return (<Checkbox value={text} />)
    } else {
        return (              
            <li className="w-full flex justify-start items-center">
                { available ? <CheckMark /> : <UnChecked /> }
                <p className={`${!available && 'text-white/50'} w-full text-md xl:text-base`}>
                    { text }
                </p>
            </li>
        )
    }
}