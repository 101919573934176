import '../../App.css';
import SectionHeading from './components/_common/SectionHeading';
import SectionBody from './components/_common/SectionBody';
import Section from './components/_common/Section';
import List from './components/_common/List';
import CircleButton from '../../components/_common/CircleButton';


function Packages() {
  return (
    <div className='Packages bg-black'>
      <h1 className="font-SpaceAge text-lg sm:text-2xl font-bold">Blxck<span className="yellow-stroke">marketing</span></h1>
      <h2 className="font-Russo text-base sm:text-lg text-white/90 leading-none">Service Packages</h2>
      <div className="flex justify-center pt-10">
        <CircleButton to="/" />
      </div>      

      <section className="text-md h-full max-w-[1600px] py-10 px-10 flex flex-col mx-auto gap-6">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 md:gap-6">
          
          <Section>
            <SectionHeading 
              title={'Basic'} 
              amount={'$750'}
              subheading={'For businesses that have content ready to be seen'}
            />
            <SectionBody>
              <List level={0} />
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading 
              title={'Standard'} 
              amount={'$1500'}
              subheading={'For business requiring moderate support with content creation and deployment.'}
            />
            <SectionBody>
              <List level={1} />
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading 
              title={'Full'} 
              amount={'$3000'}
              subheading={'For businesses requiring full content creation, website management & deployment.'}
            />
            <SectionBody>
              <List level={2} />
            </SectionBody>
          </Section>

        </div>
      </section>
    </div>
  );
}

export default Packages;

