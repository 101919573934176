import { useRef } from 'react';

export default function CircleButton({ triggerEvent, to }) {
    const container = useRef();
    const circle1 = useRef();
    const circle2 = useRef();

    const handleClick = () => {
        to ? window.location.href = to : triggerEvent()
    } 

    return (
        <button onClick={() => handleClick()} ref={container} className='font-SpaceAge text-base sm:text-lg flex items-center gap-2 border-[1px] border-white/20 rounded-full bg-white/20 hover:bg-white/40 transition-bg duration-300 z-20'>
            <img 
                ref={circle1}
                src="./blxck_ring_small.png"
                alt='click to open our contact form' 
                className="Circle h-8 w-8"
            />
            { to ? "home" : "click here"}
            <img 
                ref={circle2}
                src="./blxck_ring_small.png"
                alt='click to open our contact form' 
                className="Circle h-8 w-8"
            />
        </button>
    )
}