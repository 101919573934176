import { useState } from "react";
import { BsArrowReturnRight } from "react-icons/bs";
import { TbCircleChevronUp } from "react-icons/tb";
import ListItem from "./ListItem";

export default function Item ({ label, dropdownList, available, hidden }) {
    const [isOpen, setIsOpen] = useState(false)

    const renderDropdownList = dropdownList?.map((item) => <li key={item}><BsArrowReturnRight className="inline mr-2 h-4 w-4" />{ item }</li>)
    const onToggleView = () => {
        setIsOpen(isOpen => !isOpen)
    }

    return ( <li>
        <ul className={`list-none flex justify-between dropdownList-center gap-2 py-2 ${hidden ? "hidden" : ""}`}>
            <ListItem available={available} text={label} />
            {dropdownList && <button disabled={!available} onClick={onToggleView}>
                <TbCircleChevronUp className={`inline float-right h-6 w-6 text-white/50 transition-all duration-300 ${isOpen ? "-rotate-180 text-white/100" : "rotate-0"}`} />
            </button>}
        </ul>
        {dropdownList &&<ul className={`text-md px-6 md:pl-10 list-inside ${isOpen ? "visible" : "hidden"}`} >
            { renderDropdownList }
        </ul>}
      </li>

    )
}
