export default function SectionHeading({ title, amount, subheading }) {
    return ( <div className={`w-full flex flex-col justify-center bg-gradient-to-r h-36 rounded-t text-black border-b-[1px] border-white/80 
      ${title === 'Basic' && 'from-blx-blue to-blx-gold from-50%'} 
      ${title === "Standard" && 'from-blx-gold to-white from-50%'}
      ${title === "Full" && 'from-white/50 to-black from-10% text-white'}
      ${title === "Custom" && 'from-black/50 to-blx-blue from-10% text-white'}`}>
        <div>
          <h2 className="font-SpaceAge text-xl sm:text-2xl leading-none">{ title }</h2>
          <p className="text-md w-4/5 mb-4 mt-2 md:w-3/4 mx-auto leading-none">{ subheading }</p>
        </div>
        <h3 className="font-Russo text-lg sm:text-xl leading-none">{ amount }</h3>
      </div>
    )
}