import Item from './Item';
import list from '../../../../masterlist.json'

export default function List({ level }) {
    const hidden = ["social-newsletter", "social-post", "social-engagement", "social-newsletter"]

    function isHidden(id, available) {
        const newId = id.slice(0, id.lastIndexOf('-'))

        if (!hidden.includes(newId)) return false

        if (level === 2) {
            return available !== 2
        } else if (level < 2) {
            return available === 2
        }
    }

    return (          
        <>
            <ul className="w-full list-outside text-left flex flex-col gap-5 divide-y">
                {list.map(subsection => {
                    return (<div key={subsection.id}>
                        <h2 className="font-SpaceAge sm:text-lg leading-none pb-2 pt-6">{subsection.label}</h2>
                        {subsection.items.map(item => {
                            return <Item key={item.id} available={item.available <= level} hidden={isHidden(item.id, item.available)} label={item.label} dropdownList={item.dropdownList} />
                        })}
                    </div>)
                })}
            </ul>  
        </>          
    )
}